<template>
    <div>
        <h3
            v-if="formType"
            class="form__title"
        >
            {{ formType }}
        </h3>

        <div id="organization-form">
            <FormErrors
                :errors="errorList"
                @dismiss-errors="onDismissErrors"
            />

            <form
                ref="formRef"
                novalidate
                @submit.prevent="onSubmit"
            >
                <BaseFormField
                    v-if="formType === 'Algemeen'"
                    :required="true"
                    :errors="[errors.email]"
                >
                    <template #label>
                        <label for="email">E-mail</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="email"
                            v-model="email"
                            type="text"
                            placeholder="Vul je e-mail in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    v-if="formType === 'Algemeen'"
                    :errors="[errors.phone]"
                >
                    <template #label>
                        <label for="phone">Telefoonnummer</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="phone"
                            v-model="phone"
                            type="text"
                            placeholder="Vul het telefoonnummer in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    v-if="formType === 'Algemeen'"
                    :errors="[errors.website]"
                >
                    <template #label>
                        <label for="website">Website</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="website"
                            v-model="website"
                            type="text"
                            placeholder="Vul de website in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    v-if="formType === 'Bezoekadres'"
                    :errors="[errors.visitingAddress]"
                >
                    <template #label>
                        <label for="visiting_address">Bezoekadres</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="visiting_address"
                            v-model="visitingAddress"
                            type="text"
                            placeholder="Vul het bezoekadres in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    v-if="formType === 'Bezoekadres'"
                    :errors="[errors.visitingZipcode]"
                >
                    <template #label>
                        <label for="visiting_zipcode">Postcode</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="visiting_zipcode"
                            v-model="visitingZipcode"
                            type="text"
                            placeholder="Vul het postcode in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    v-if="formType === 'Bezoekadres'"
                    :errors="[errors.visitingCity]"
                >
                    <template #label>
                        <label for="visiting_city">Stad</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="visiting_city"
                            v-model="visitingCity"
                            type="text"
                            placeholder="Vul de stad in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    v-if="formType === 'Bezoekadres'"
                    :errors="[errors.visitingCountry]"
                >
                    <template #label>
                        <label for="visiting_country">Land</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="visiting_country"
                            v-model="visitingCountry"
                            type="text"
                            placeholder="Vul het land in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    v-if="formType === 'Postadres'"
                    :errors="[errors.postalAddress]"
                >
                    <template #label>
                        <label for="postal_address">Postadres</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="postal_address"
                            v-model="postalAddress"
                            type="text"
                            placeholder="Vul het postadres in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    v-if="formType === 'Postadres'"
                    :errors="[errors.postalZipcode]"
                >
                    <template #label>
                        <label for="postal_zipcode">Postcode</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="postal_zipcode"
                            v-model="postalZipcode"
                            type="text"
                            placeholder="Vul het postcode in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    v-if="formType === 'Postadres'"
                    :errors="[errors.postalCity]"
                >
                    <template #label>
                        <label for="postal_city">Stad</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="postal_city"
                            v-model="postalCity"
                            type="text"
                            placeholder="Vul de stad in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    v-if="formType === 'Postadres'"
                    :errors="[errors.postalCountry]"
                >
                    <template #label>
                        <label for="postal_country">Land</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="postal_country"
                            v-model="postalCountry"
                            type="text"
                            placeholder="Vul het land in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField>
                    <template #default>
                        <div class="form__actions">
                            <BaseButton
                                type="button"
                                class="base-button--transparent-dark"
                                size="medium"
                                @click="$emit('dismiss')"
                            >
                                Annuleer
                            </BaseButton>

                            <BaseButton
                                type="submit"
                                size="medium"
                                :is-loading="isLoading"
                            >
                                Wijzigingen opslaan
                            </BaseButton>
                        </div>
                    </template>
                </BaseFormField>
            </form>
        </div>
    </div>
</template>

<script setup>
import { useForm } from 'vee-validate';
import { object, string } from 'yup';
import { useAuthStore } from '~/store/auth';
import { useGtm } from '@gtm-support/vue-gtm';

const props = defineProps({
    organization: {
        type: Object,
        required: true,
    },
    successRedirectPath: {
        type: String,
        default: ''
    },
    formType: {
        type: String,
        required: true
    }
});

const { organization, successRedirectPath } = toRefs(props);

const emit = defineEmits(['dismiss']);

const authStore = useAuthStore();

const globalErrors = {
    fallback: 'Er is iets misgegaan bij het wijzigen. Probeer het later nog eens.',
};

// Set validation schema
const validationSchema = object({
    email: string().required('E-mailadres is verplicht'),
    phone: string(),
    website: string(),
    visitingAddress: string(),
    visitingZipcode: string(),
    visitingCity: string(),
    visitingCountry: string(),
    postalAddress: string(),
    postalZipcode: string(),
    postalCity: string(),
    postalCountry: string()
});

const { errors, defineField, handleSubmit } = useForm({
    validationSchema
});

// Define models
const [email] = defineField('email');
const [phone] = defineField('phone');
const [website] = defineField('website');
const [visitingAddress] = defineField('visitingAddress');
const [visitingZipcode] = defineField('visitingZipcode');
const [visitingCity] = defineField('visitingCity');
const [visitingCountry] = defineField('visitingCountry');
const [postalAddress] = defineField('postalAddress');
const [postalZipcode] = defineField('postalZipcode');
const [postalCity] = defineField('postalCity');
const [postalCountry] = defineField('postalCountry');

onMounted(() => {
    setInitialValues();
});

// Wait till organization modal data is available
watch(organization, () => {
    setInitialValues();
});

// Generic error list
const errorList = ref([]);
const isLoading = ref(false);

const onSubmit = handleSubmit(values => {
    submitDataToApi(values);
});

const submitDataToApi = async function(formData) {
    errorList.value = [];
    isLoading.value = true;

    try {
        await useLaravelApi(
            `/user/${authStore.windexId}/organization/${organization.value.id}`,
            {
                method: 'PUT',
                data: {
                    ...formData
                },
                accessToken: authStore.accessToken
            }
        ).then(() => {
            useGtm()?.trackEvent({
                event: 'form_submit_custom',
                formHandle: 'organizationMutationForm'
            });

            if (successRedirectPath.value) {
                emit('dismiss');
                return navigateTo(successRedirectPath.value);
            }
        }).catch(error => {
            setErrorMessage(error.response?.data?.code);
        });

        isLoading.value = false;
    } catch (error) {
        setErrorMessage();
        isLoading.value = false;
    }
};

function setErrorMessage(errorCode) {
    const errorMessage = globalErrors[errorCode] ? globalErrors[errorCode] : globalErrors.fallback;
    errorList.value.push(errorMessage);
}

function onDismissErrors() {
    errorList.value = [];
}

const setInitialValues = () => {
    if (!organization.value) {
        return;
    }

    email.value = organization.value.email || '';
    phone.value = organization.value.phone || '';
    website.value = organization.value.website || '';
    visitingAddress.value = organization.value.visitingAddress || '';
    visitingZipcode.value = organization.value.visitingZipcode || '';
    visitingCity.value = organization.value.visitingCity || '';
    visitingCountry.value = organization.value.visitingCountry || '';
    postalAddress.value = organization.value.postalAddress || '';
    postalZipcode.value = organization.value.postalZipcode || '';
    postalCity.value = organization.value.postalCity || '';
    postalCountry.value = organization.value.postalCountry || '';
};
</script>

<style lang="less" src="./OrganizationMutationForm.less" />
