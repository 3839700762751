<template>
    <BaseModal :is-visible="modalStore.activeModals['organization-mutation'] === true">
        <template #title>Wijzig mijn organisatie</template>
        <template #default>
            <OrganizationMutationForm
                :organization="modalData.organization"
                :success-redirect-path="successRedirectPath"
                :form-type="modalData.formType"
                @dismiss="dismissModal"
            />
        </template>
    </BaseModal>
</template>

<script setup>
import { useModalStore } from '~/store/modal';
import { useNavigationStore } from '~/store/navigation';

const modalStore = useModalStore();

const modalData = computed(() => {
    return modalStore.data['organization-mutation'];
});

const mijnTlnGlobalData = computed(() => {
    return useNavigationStore().mijnTln;
});

const successRedirectPath = computed(() => {
    const successEntry = toValue(mijnTlnGlobalData).successEntryMutateOrganization?.[0];

    if (!successEntry) {
        return;
    }

    return `/${successEntry.uri}`;
});

const dismissModal = () => {
    modalStore.deactivateAllModals();
};
</script>

<style lang="less" src="./OrganizationMutationModal.less" />
